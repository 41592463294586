import Bowser from 'bowser';
import isWebView from 'is-ua-webview';

export enum DEVICE_TYPE {
  APP = 'app',
  WEB_MOBILE = 'web-mobile',
  WEB_desktop = 'web-desktop'
}

export const isMobileDevice = () => {
  // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
  const mobileDevicesRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return mobileDevicesRegex.test(navigator.userAgent)
}

export const normalizeUrl = (url?: string) => {
  if (!url) return;
  if (url.lastIndexOf('/') === url.length - 1) {
    return url;
  } else {
    return `${url}/`
  }
};

export const stringToBoolean = (str: string) => {
  return str === 'true';
};

export const isIosWebView = () => {
  // standalone is a safari only attribute
  const standalone = (window.navigator as any).standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (!standalone && safari) {
      return false;
    } else if (!standalone && !safari) {
      return true;
    };
  } else {
    return false;
  };
};

export const isAndroidView = () => {
  return isWebView(window.navigator.userAgent);
};

// https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || '';

  // Windows Phone must come first because its UA also contains 'Android'
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export const getBrowserDeviceData = () => {
  const MAX_LENGTH = 20;
  let clientPlatform = isMobileDevice() ? DEVICE_TYPE.WEB_MOBILE : DEVICE_TYPE.WEB_desktop
  const browser = Bowser.parse(window.navigator.userAgent);
  const browserName = browser?.browser?.name?.substring(0, MAX_LENGTH);
  const browserOs = browser?.os?.name?.substring(0, MAX_LENGTH);
  const mobileOs = getMobileOperatingSystem().substring(0, MAX_LENGTH);


  if (isIosWebView() || isAndroidView()) {
    clientPlatform = DEVICE_TYPE.APP;
  }

  return {
    clientPlatform: clientPlatform,
    clientBrowser: browserName || 'unknown-browser',
    clientOs: browserOs || mobileOs || 'unknown-os'
  }
};
