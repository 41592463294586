// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import HomePage from './component/home-page/HomePage';
import { MagazineContextProvider, TimeContextProvider } from './providers/magazine';
import './index.scss';
import "./services/i18n";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading...">
      <MagazineContextProvider>
        <TimeContextProvider>
          <HomePage />
        </TimeContextProvider>
      </MagazineContextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
