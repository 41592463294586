import { FunctionComponent } from 'react';
import alertStyle from "./AlertContainer.module.scss"

interface IAlertContainerProps {
  children: React.ReactNode;
  show: boolean;
}

const AlertContainer: FunctionComponent<IAlertContainerProps> = ({ children, show }) => {
  return (
    <div>
      {show ? (
        <div className={alertStyle.Alert}>
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default AlertContainer;