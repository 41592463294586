export const owsDateFormat = 'YYYY-MM-DDTHH:mm:ssZ';

export enum CLOCKING_STATUS {
  IN = 'IN',
  OUT = 'OUT'
};

export interface CLOCKING_DATA {
  inOut: CLOCKING_STATUS,
  dateTime: string,
  employeeNumber: string,
  isFirstClocking?: boolean
};