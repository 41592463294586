import { useState, createContext, useContext, useEffect } from 'react';
import { getMagazineConfig } from '../services/api';
import dayjs from 'dayjs';
import CONFIG from '../services/config';


const magazineContext = createContext({});
const timeContext = createContext({});

export function TimeContextProvider({ children }: any) {
  const magazineContext: any = useMagazineContext();

  const [date, setDate] = useState(dayjs().tz(magazineContext?.magazine?.timezone || CONFIG.DEFAULT_TIMEZONE));

  useEffect(() => {
    const timerID = setInterval(function headerClockTimer() {
      setDate(dayjs().tz(magazineContext?.magazine?.timezone || CONFIG.DEFAULT_TIMEZONE));
    }, 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, [magazineContext]);

  return (
    <timeContext.Provider value={date}>
      {magazineContext.magazine && children}
    </timeContext.Provider>
  )
}

export function MagazineContextProvider({ children }: any) {
  const magazineContextValue = useMagazine();

  return (
    <magazineContext.Provider value={magazineContextValue}>
      {magazineContextValue.magazine && children}
    </magazineContext.Provider>
  );
}

export function useLocalDateTimeContext() {
  return useContext(timeContext as any);
}

export function useMagazineContext() {
  return useContext(magazineContext as any);
}

function useMagazine() {
  const [magazine, setMagazine] = useState<any | null>(null);
  const [error, setError] = useState(false);

  async function getMagazine() {
    try {
      const json = await getMagazineConfig();
      setMagazine(json);
      setError(false);
    } catch (err: any) {
      if (err.message?.toLowerCase().includes("expired")) throw err;
      setError(true);
    }
  }
  useEffect(() => {
    getMagazine()
  }, []);

  return {
    magazine,
    error
  };
}
