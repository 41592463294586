import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { ReactComponent as AlertIcon } from './icon-alert.svg';
import clsx from 'clsx';
import Button from '../button/ButtonComponent';
import { useTranslation } from 'react-i18next';
import contentStyle from "./alertContent.module.scss"


interface IAlertContentProps {
    delayed: boolean;
    displayAlert: {
        visible: boolean;
        message: string;
    };
    setDisplayAlert: Dispatch<SetStateAction<{
        visible: boolean;
        message: string;
    }>>
}

const AlertContent: FunctionComponent<IAlertContentProps> = ({ delayed, displayAlert, setDisplayAlert }) => {
    const { t } = useTranslation();

    return (
        <div className={clsx(contentStyle.contentWrapper, delayed && contentStyle.show)}>
            <div className={contentStyle.header}>
                <AlertIcon /> <span>{t('Something went wrong')}</span>
            </div>
            <div className={contentStyle.content}>{displayAlert.message}</div>
            <div className={contentStyle.footer}>
                <Button className={contentStyle.Button} onClick={() => { setDisplayAlert({ visible: false, message: '' }) }}>OK</Button>
            </div>
        </div>
    );
};

export default AlertContent;