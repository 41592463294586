import React, { FunctionComponent } from 'react';
import './ButtonComponent.scss';
import { ThemeColorVariant } from '../../providers/theme/themeComponentMapping';
import clsx from 'clsx';

interface IButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: () => Promise<void> | void;
  variant?: ThemeColorVariant
}

const Button: FunctionComponent<IButtonProps> = ({ children, disabled, className, onClick }) => {
  return (
    <button
      disabled={disabled}
      className={clsx('Button', className)}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;
