import 'dayjs/locale/nl';
import { useMagazineContext, useLocalDateTimeContext } from '../../providers/magazine';
import { useTranslation } from 'react-i18next';
import headerClockStyle from "./HeaderClock.module.scss"

const colors = {
  blue: '#1C78B5',
  green: '#03AC00',
  orange: '#E56020'
};

enum GZA_ORGANISATION {
  Ziekenhuizen = 'Ziekenhuizen',
  Zorg_en_Wonen = 'Zorg_en_Wonen'
};

const dateFormat = `dddd DD MMMM YYYY`;
const timeFormat = `HH:mm:ss`;

function getOrganizationColor(magazineContext: any): string {
  if (magazineContext?.magazine?.organization === GZA_ORGANISATION.Ziekenhuizen) {
    return colors.blue;
  } else {
    return colors.orange;
  }
}
function HeaderClock(props: { isTimerOn: boolean }) {
  // todo: type contexts
  const magazineContext: any = useMagazineContext();
  const date: any = useLocalDateTimeContext();
  const { i18n } = useTranslation();


  return (
    <div className={headerClockStyle.HeaderClock} style={{ 'backgroundColor': props.isTimerOn ? colors.green : getOrganizationColor(magazineContext) }}>
      <div className={headerClockStyle.clockDate}>
        {date.locale(i18n.language).format(dateFormat)}
      </div>
      <div className={headerClockStyle.clockTime}>
        {date.format(timeFormat)}
      </div>
    </div>
  );
}

export default HeaderClock;
