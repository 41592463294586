import { FunctionComponent } from 'react';
import clsx from 'clsx';
import { CLOCKING_DATA, CLOCKING_STATUS, owsDateFormat } from '../../services/interfaces';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import { TFunction, useTranslation } from 'react-i18next';
import { useMagazineContext } from '../../providers/magazine';
import CONFIG from '../../services/config';
import lastClockingStyle from "./LastClocking.module.scss"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

interface ILastClockingProps {
    isUserIpAllowed: boolean;
    secondsBeforeNextAction: number;
    lastClocking: CLOCKING_DATA;
}

const getLastClockingTitle = (secondsBeforeNextAction: number, lastClocking: CLOCKING_DATA, t: TFunction<"translation", undefined>): string => {
    if (secondsBeforeNextAction !== 0) {
        if (lastClocking.inOut === CLOCKING_STATUS.IN) {
            return t('clockOutAllowed', { seconds: secondsBeforeNextAction });
        } else if (lastClocking.inOut === CLOCKING_STATUS.OUT) {
            return t('clockinAllowed', { seconds: secondsBeforeNextAction });
        }
    }
    if (lastClocking.inOut === CLOCKING_STATUS.IN) {
        return t('lastClockIn');
    } else if (lastClocking.inOut === CLOCKING_STATUS.OUT) {
        return t('lastClockOut');
    } else {
        return '';
    }
};


const getLastClockingDate = (secondsBeforeNextAction: number, lastClocking: CLOCKING_DATA, t: TFunction<"translation", undefined>, magazineContext: any): string => {
    if (!lastClocking.dateTime) {
        return '';
    }

    if (secondsBeforeNextAction !== 0) {
        return `${secondsBeforeNextAction} ${t('seconds')}`;
    }

    const lastClockingDayjs = dayjs(lastClocking.dateTime, owsDateFormat).tz(magazineContext?.magazine?.timezone || CONFIG.DEFAULT_TIMEZONE);
    const diffDaysFromNow = dayjs().tz(magazineContext?.magazine?.timezone || CONFIG.DEFAULT_TIMEZONE).diff(lastClockingDayjs, 'day');

    if (dayjs().isSame(lastClockingDayjs, 'day')) {
        return `${t('todayAt')} ${lastClockingDayjs.format('HH:mm')}`
    } else if (diffDaysFromNow <= 1) {
        return `${t('yesterdayAt')} ${lastClockingDayjs.format('HH:mm')}`
    } else if (diffDaysFromNow > 1 && diffDaysFromNow <= 300) {
        return `${diffDaysFromNow} ${t('lastTime')} ${lastClockingDayjs.format('HH:mm')}`;
    } else {
        return `${t('lastRecord')} ${lastClockingDayjs.format('DD-MM-YYYY - HH:mm')}`
    }
};

const LastClocking: FunctionComponent<ILastClockingProps> = ({ isUserIpAllowed, secondsBeforeNextAction, lastClocking }) => {
    const { t } = useTranslation();
    const magazineContext: any = useMagazineContext();

    return (
        <div className={clsx(lastClockingStyle.lastClockingStatus, !isUserIpAllowed && lastClockingStyle.faded)}>
            <div className={lastClockingStyle.title}>{getLastClockingTitle(secondsBeforeNextAction, lastClocking, t)}</div>
            <div className={lastClockingStyle.date}>{getLastClockingDate(secondsBeforeNextAction, lastClocking, t, magazineContext)}</div>
        </div>
    );
};

export default LastClocking;