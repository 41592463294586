import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import statusStyle from "./HeaderUserStatus.module.scss"
import duration from 'dayjs/plugin/duration';
import { useLocalDateTimeContext, useMagazineContext } from '../../providers/magazine';
import CONFIG from '../../services/config';
import { CLOCKING_DATA, CLOCKING_STATUS, owsDateFormat } from '../../services/interfaces';
import { useTranslation } from 'react-i18next';

dayjs.extend(duration);

function getFormattedDuration(duration: duration.Duration, localTime: any) {
  if (!duration) return '';
  return <span className={statusStyle.runningClock}>
    {Math.floor(duration.as('hour'))}
    :
    {duration.format('mm')}
    :
    {localTime.format('ss')}
  </span>
}

function getDurationFromNow(dateToCompare: Dayjs, localDateTime: Dayjs) {
  localDateTime.second(0);
  return dayjs.duration(localDateTime.diff(dateToCompare));
};

function HeaderUserStatus(props: { lastClocking: CLOCKING_DATA }) {
  const magazineContext: any = useMagazineContext();
  const localDateTime: any = useLocalDateTimeContext();
  const [inOutStatus, setInOutStatus] = useState(props.lastClocking.inOut);
  const [runningTimer, setRunningTimer] = useState<null | any>({
    lastClocking: null,
    duration: null
  });

  const { t } = useTranslation()

  useEffect(() => {
    if (!props.lastClocking.dateTime) {
      return;
    }

    if (props.lastClocking.inOut === CLOCKING_STATUS.IN) {
      const lastClockingDayjs = dayjs(props.lastClocking.dateTime, owsDateFormat).tz(magazineContext?.client?.timezone || CONFIG.DEFAULT_TIMEZONE);
      setRunningTimer({
        lastClocking: lastClockingDayjs,
        duration: getDurationFromNow(lastClockingDayjs, localDateTime)
      });
    } else {
      setRunningTimer(null);
    }

    setInOutStatus(props.lastClocking.inOut);
  }, [props, magazineContext, localDateTime]);

  useEffect(() => {
    const timerID = setInterval(function headerStatusTimer() {
      if (runningTimer) {
        setRunningTimer({
          ...runningTimer,
          duration: getDurationFromNow(runningTimer.lastClocking, localDateTime)
        });
      }
    }, 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  }, [runningTimer, localDateTime])

  return (
    <div className={statusStyle.HeaderUserStatus}>
      {
        inOutStatus !== CLOCKING_STATUS.IN &&
        t('youClockedOut')
      }
      {
        inOutStatus === CLOCKING_STATUS.IN && runningTimer &&
        getFormattedDuration(runningTimer.duration, localDateTime)
      }
    </div>
  );
}

export default HeaderUserStatus;
